<template>
  <div>
    <el-drawer class="assignOrderDetail-drawer" title="订单详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div>
              <div class="detail-title">
                <div class="tit-info">
                    <div class="orderName">{{ detailInfo.disOrderCode || "--" }}</div>
                    <div v-if="detailInfo.failFlag == '0'" class="common-tableStatus-blue">接收成功</div>
                    <div v-if="detailInfo.failFlag == '1'" class="common-tableStatus-red">拒绝接收</div>
                    <div v-if="detailInfo.failFlag == '2'" class="common-tableStatus-grey">作废</div>
                    <div v-if="detailInfo.failFlag == '-1'" class="common-tableStatus-orange">待接收</div>
                </div>
                <!-- <div class="handle-btn" @click="() => { $router.push(`/assign-order/assign-list/assign-one?id=${detailInfo.disOrderNo}&isEdit=${true}`) }">返回派单详情</div> -->
              </div>
              <div class="detail-content">
                  <div class="detail-item">公司名称：<span>{{detailInfo.companyName || '--'}}</span></div>
                  <div class="detail-item">订单名称：<span>{{detailInfo.disOrderName || '--'}}</span></div>
                  <div class="detail-item">业务类型：<span>{{detailInfo.typeName || '--'}}</span></div>
              </div>
              <div class="detail-remark"><span>备注：</span> <span>{{detailInfo.remark || '--'}}</span></div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">上传附件<input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteFlie(index)">
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无附件
              </div>
            </div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane :label="`店铺列表（${detailInfo.shopList && detailInfo.shopList.length || 0}）`" name="service">
                <div class="shop-table-container">
                    <div class="shop-table-item" v-for="(item, index) in detailInfo.shopList" :key="index">
                        <div class="scoped-conmon">
                            <div class="leftRight">
                                <div class="shopItem-title-conatiner">
                                    <div class="shopItem-title-left">
                                        <img class="common-iconImg-MR" v-if="item.shopLogo" :src="item.shopLogo" alt="">
                                        <i v-else class="el-icon-user common-iconImg-MR"></i>
                                        <div class="shopName-platCategory">
                                            <div>{{item.shopName || '--'}}</div>
                                            <div>{{item.platformName}}/{{item.categoryName}}</div>
                                        </div>
                                    </div>
                                    <div class="shopItem-title-right">
                                        <div class="rightItem">
                                            <div>客户名称</div>
                                            <div>{{item.customerName || '--'}}</div>
                                        </div>
                                        <div class="rightItem">
                                            <div>协议客服工作时长</div>
                                            <div>{{item.serviceWorkTime || '0'}}小时</div>
                                        </div>
                                        <div class="rightItem">
                                            <div>协议子账号数量</div>
                                            <div>{{item.agreeSubNum || '0'}}个</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="leftRight">
                                <div class="text-blue">{{ item.shopServiceDay || '0' }}天 </div>
                                <div class="text-black">{{item.startTime || ''}} ~ {{ item.endTime || '' }}</div>
                            </div>
                        </div>
                        <el-form>
                            <el-form-item label="服务内容：" >
                                <el-radio v-if="item.serviceContent && item.serviceContent.split(',').includes('1')" :value="true" :label="true">售前</el-radio>
                                <el-radio v-if="item.serviceContent && item.serviceContent.split(',').includes('2')" :value="true" :label="true">售中</el-radio>
                                <el-radio v-if="item.serviceContent && item.serviceContent.split(',').includes('3')" :value="true" :label="true">售后</el-radio>
                                <el-radio v-if="item.serviceContent && item.serviceContent.split(',').includes('4')" :value="true" :label="true">附加项</el-radio>
                            </el-form-item>
                            <div class="sub-List">
                                <div class="subList-header">
                                    <div class="table-fontsize" style="width: 40px"></div>
                                    <div class="table-fontsize">子账号名称</div>
                                    <div class="table-fontsize">子账号密码</div>
                                    <div class="table-fontsize">管理员手机号</div>
                                    <div class="table-fontsize">指派客服</div>
                                    <div class="table-fontsize">服务时间</div>
                                    <div class="table-fontsize">备注</div>
                                    <div class="table-fontsize">创建人</div>
                                    <div class="table-fontsize">创建时间</div>
                                </div>
                                <div v-if="item.subAccountList && item.subAccountList.length" class="subList-content">
                                    <div class="subList-item" v-for="(itemTwo, indexTwo) in item.subAccountList" :key="indexTwo">
                                        <div class="table-fontsize" style="width: 40px;text-indent: 8px"></div>
                                        <div class="table-fontsize">{{itemTwo.subAccountName || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.subAccountPassword || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.phone || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.nickName || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.startTime || ''}} ~ {{itemTwo.endTime || ''}}</div>
                                        <div class="table-fontsize">{{itemTwo.note || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.createName || '--'}}</div>
                                        <div class="table-fontsize">{{itemTwo.createTime || '--'}}</div>
                                    </div>
                                </div>
                                <div v-else class="subList-content">
                                    <div style="text-align: center">暂无数据</div> 
                                </div>
                            </div>
                            <br/>
                            <el-form-item label="派单金额：" >
                                <div> <span style="font-size: 14px;color: #1890ff;">￥</span><span class="text-blue">{{item.price || '0'}}</span></div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="baseInfo">
                <div class="detail-content">
                    <div class="DisableEdit">通知人: <span class="common-clr-0a6">{{(detailInfo && detailInfo.noticeName) || "--"}}</span></div>
                    <div class="DisableEdit">通知人所属部门: <span class="common-clr-0a6">{{(detailInfo && detailInfo.noticeDepartName) || "--"}}</span></div>
                    <div class="DisableEdit">创建人: <span class="common-clr-0a6">{{(detailInfo && detailInfo.createName) || "--"}}</span></div>
                    <div class="DisableEdit">创建时间: <span class="common-clr-0a6">{{(detailInfo && detailInfo.createTime) || "--"}}</span></div>
                </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
              <div>
                <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="工作小计"></el-input>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button @click="() => {addWorkLog()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { Config } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { assignOrderDetail, orderWorkLog, assignOrderUpload, assignOrderDelete, addWorkLog } from "../../../service/assignOrder.js"
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
export default {
  props: ["drawer", "handleClose", "companyId"],
  components: { CommonPicture, CommonWorkLog },
  data() {
    return {
      loading: false,
      Config,
      fileList:[],
      activeName: "appeal", // tabs
      activeNameLeft: "service", // 左侧tabs
      recordContent: '', // 工作小计
      filesList: [],  // 工作小计文件上传
      detailInfo: {}, // 详情
      followRecordList: [], // 工作日志
    };
  },
  methods: {
    async deleteFlie (i) {  //  删除文件
        let fileList = [...this.fileList]
        let deleteItem = {}
        fileList.map((item, index) => {
            if (i == index) {
                deleteItem = {
                    fileName: item.name,
                    filedUrl: item.url,
                    filedId: item.id,
                }
            }
        })
        deleteItem.disOrderNo = this.detailInfo.disOrderNo
        this.loading = true
        let { code } = await assignOrderDelete(deleteItem)
        this.loading = false
        if ( code == 200 ) {
            this.$message.success("删除成功")
            this.assignOrderDetail(this.detailInfo.disOrderNo)
            this.orderWorkLog(this.detailInfo.disOrderNo)
        }
    },
    async uploadFile (e) { // 上传文件
        let file = e.target.files[0];
        let fileName = file.name;
        let format = file.name.split(".")[file.name.split(".").length - 1];
        if (file.size / 1024 > 1024 * 50) {
            e.target.value = "";
            return this.$message.error('文件大小不能超过50M')
        }
        if (!Config.fileType.PWEIType.includes(format)) {
            e.target.value = "";
            return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
        }
        this.loading = true
        let resData = (await upload({ file: file })).data;
        let { code } = await assignOrderUpload({disOrderNo: this.detailInfo.disOrderNo, filedId: resData.id, filedUrl: resData.filename, fileName: fileName})
        this.loading = false
        if ( code == 200 ) {
            this.$message.success("上传成功")
            this.assignOrderDetail(this.detailInfo.disOrderNo)
            this.orderWorkLog(this.detailInfo.disOrderNo)
        }
        e.target.value = ''
    },
    
    download(url) { // 下载
      window.location.href = url
    },
    async addWorkLog() { // 工作记录发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
        disOrderNo: this.detailInfo.disOrderNo,
        content: this.recordContent,
        fileIds: filesId.join(','),
        filedName: filesName.join(','),
        filedUrl: filesUrl.join(','),
      };
      this.loading = true
      let { code } = await addWorkLog(params)
      this.loading = false
      if (code == 200) {
        this.orderWorkLog(this.detailInfo.disOrderNo)
        this.filesList = []
        this.recordContent = "";
        this.$message.success("添加成功");
      }
    },
    getTableParams (row) { // 获取列表的值
      this.assignOrderDetail(row.disOrderNo)
      this.orderWorkLog(row.disOrderNo)
    },
    async assignOrderDetail (id) {
      let { data } = await assignOrderDetail({disOrderNo: id})
      if (data.fileList && data.fileList.length) {
        let fileList = []
        data.fileList.map((item) => {
          fileList.push({url: item.filedUrl, name: item.filedName, id: item.filedId})
        })
        this.fileList = fileList
      } else {
        this.fileList = []
      }
      this.detailInfo = data
    },
    async orderWorkLog(id) { // 工作日志
      let { data } = await orderWorkLog({realtionId: id})
      if (data && data.length) {
        data.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...data]
    },
    deleteFile (id) { // 删除工作日志文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e) { // 添加工作日志文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
  },
};
</script>
<style lang="less" scoped>
.shop-table-container {
    padding-bottom: 48px;
    .text-blue {
        color: #1890ff;
        font-size: 36px;
        vertical-align: middle;
    }
    .common-iconImg-MR {
        height: 64px;
        width: 64px;
        line-height: 64px;
        font-size: 48px;
        border: 1px solid #b9b9b9;
    }
    .shop-table-item {
        border: 1px solid #bbb;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        padding: 32px;
        margin-top: 20px;
        .sub-List {
            border-radius: 4px;
            border: 1px solid #eaeaeb;
            max-height: 352px;
            position: relative;
            width: 100%;
            .table-fontsize {
                font-size: 14px;
                width: 170px;
            }
            .subList-header {
                height: 50px;
                width: 100%;
                background: #f2f2f2;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 4px 4px 0 0;
                display: flex;
                align-items: center;
                white-space: nowrap;

            }
            .subList-content {
                margin-top: 50px;
                max-height: 300px;
                overflow-y: scroll;
                width: 100%;
                .subList-item {
                    border-bottom: solid 1px #eaeaeb;
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                }
            }
            
        }
        .scoped-conmon {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            .leftRight {
                &:nth-of-type(1) {
                    width: 74%;
                    border-right: 1px solid #bbb;
                    .shopItem-title-conatiner {
                        display: flex;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding-right: 39px;
                        .shopItem-title-right {
                            display: flex;
                            align-items: center;
                            .rightItem {
                                margin-left: 32px;
                                div {
                                    color: #333;
                                    &:nth-of-type(1) {
                                        font-size: 14px;
                                        margin-bottom: 12px;
                                    }
                                    &:nth-of-type(2) {
                                        font-size: 28px;
                                    }
                                }
                            }
                        }
                        .shopItem-title-left {
                            display: flex;
                            align-items: center;
                            .shopName-platCategory {
                                color: #333;
                                div {
                                    &:nth-of-type(1) {
                                        font-size: 18px;
                                        margin-bottom: 12px;
                                        color: #1890FF;
                                    }
                                    &:nth-of-type(2) {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .shopItem-num {
                                width: 58px;
                                height: 58px;
                                border-radius: 50%;
                                color: #fff;
                                font-size: 32px;
                                background: #1890ff;
                                text-align: center;
                                line-height: 58px;
                                font-weight: 700;
                                margin-right: 34px;
                            }
                        }
                    }
                    .assign-detail {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .assign-detail-text {
                            width: 48%;
                            line-height: 32px;
                        }
                        .detail-text-warp {
                            display: flex;

                        }
                    }
                }
                &:nth-of-type(2) {
                    width: 22%;
                    height: 90px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    .text-black {
                        color: #333;
                        font-size: 14px;
                    }
                    .text-blue {
                        color: #1890ff;
                        font-size: 42px;
                    }
                }
            }
        }
    }
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
    font-size: 12px;
    color: #333;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
    .deleteBtn {
    color: #1890ff;
    cursor: pointer;
    }
  }
}
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.assignOrderDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .upload-container {
        margin-top: 48px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .detail-title {
        line-height: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit-info {
            display: flex;
            align-items: center;
            .orderName {
                color: #101010;
                font-size: 18px;
                margin-right: 16px;
            }
        }
        .handle-btn {
          width: 120px;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          color: #fff;
          background: #1890FF;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          cursor: pointer;
        }
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
        .DisableEdit {
            display: inline-block;
            width: 323px;
            height: 21px;
            padding: 6px 12px;
            font-size: 12px;
            color: #999;
            margin-right: 16px;
            border-radius: 4px;
        }
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
}
</style>
